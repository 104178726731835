
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.program-v6__inner {
  margin-right: 4px;
  margin-left: 4px;
  padding-top: 56px;
  padding-bottom: 56px;
  border-radius: 20px;
  background-color: var(--bg-secondary-color);

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px;
    border-radius: 32px;
  }

  @include media(xl) {
    width: calc(100vw - 40px);
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
  }
}

.program-v6__wrapper {
  @include media(sm-max) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.program-v6__title {
  margin-bottom: 20px;

  @include media(lg) {
    width: 768px;
    margin-bottom: 32px;
  }
}

.program-v6__stats {
  @include ulres;

  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-bottom: 28px;

  @include media(lg) {
    margin-right: -24px;
    margin-bottom: 48px;
  }
}

.program-v6__stat {
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 12px;

  @include media(lg) {
    margin-right: 24px;
    margin-bottom: 24px;
  }
}

.program-v6__icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  @include media(lg) {
    width: 20px;
    height: 20px;
  }
}

.program-v6__program {
  @include ulres;
}

.program-v6__group {
  &:not(:last-child) {
    margin-bottom: 32px;

    @include media(lg) {
      margin-bottom: 56px;
    }
  }
}

.program-v6__group-title {
  margin-bottom: 16px;

  @include media(md) {
    margin-bottom: 20px;
  }
}

.program-v6__pic {
  width: 44px;
  height: 44px;
  margin-bottom: 6px;

  @include media(lg) {
    grid-row: 1/-1;
    grid-column: 1/2;
    width: 56px;
    height: 56px;
    margin-bottom: 0;
  }
}

.program-v6__inner-list {
  @include media(lg) {
    width: 568px;
  }

  li {
    padding-left: 18px;

    &::before {
      top: 7px;

      @include media(lg) {
        top: 8px;
      }
    }
  }
}

// TODO: выпилить костыли, когда обновятся аккордион и спойлеры в ДС
.program-v6__accordion {
  &.ui-accordion {
    border-radius: 20px;

    @include media(lg) {
      border-radius: 32px;
    }
  }
}
